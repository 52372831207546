<template>
    <v-container justify="center">
      <v-row justify="center" class="mt-10">
        <v-img alt="Familytablet"
        src='../../assets/logo.png'
        max-width="200"
        ></v-img>
      </v-row>
      <v-row justify="center" class="mt-10">
        <v-form @submit.prevent="userRegistration">
            <div class="text-h4 mb-8 mt-5">Registrierung</div>
            <v-item-group>
                <v-text-field
                  placeholder="Dein Vorname"
                  v-model="user.name"
                  id='userName'
                  required
                  :rules="[v => !!v || 'Ein Name ist erforderlich']"
                >
                </v-text-field>
            </v-item-group>

            <v-item-group>
                <v-text-field
                  placeholder="Email"
                  type="email"
                  v-model="user.email"
                  id='email'
                  required
                  :rules="[v => !!v || 'Eine Email ist erforderlich']"
                />
            </v-item-group>

            <v-item-group>
                <v-text-field
                  placeholder="Passwort"
                  type="password"
                  v-model="user.password"
                  id='password'
                  required
                  :rules="[v => !!v || 'Ein Passwort ist erforderlich']"
                />
            </v-item-group>

            <v-btn type="submit" outlined large color="#013333" class="mt-10 ml-3" id='signup'>
               Registrieren
            </v-btn>

            <p class="forgot-password text-right mt-5">
                Bereits registriert?
                <router-link :to="{name: 'login'}">Einloggen</router-link>
            </p>
        </v-form>
      </v-row>
    </v-container>
</template>

<script>
import { FirebaseAuth } from '../../firebase'
import User from '../../user/User'
import Family from '../../family/Family'

export default {
  data () {
    return {
      user: {
        name: '',
        email: '',
        password: '',
        uid: ''
      }
    }
  },
  mounted () {
    const family = new Family(this.$route.params.id)
    if (!family.id) {
      this.$router.push('/')
    }
  },
  methods: {
    userRegistration () {
      if (this.user.name === '' || this.user.email === '' || this.user.password === '') {
        console.log('Empty Field')
        return
      }
      const family = new Family(this.$route.params.id)
      if (!family.id) {
        return
      }

      FirebaseAuth
        .createUserWithEmailAndPassword(this.user.email, this.user.password)
        .then((res) => {
          this.user.uid = res.user.uid
          res.user
            .updateProfile({
              displayName: this.user.name,
              photoURL: 'https://res.cloudinary.com/da8mew2gy/image/upload/avatar-1577909_640_dxcyoz.png'
            })
            .then(() => {
              const user = new User(this.user.name, this.user.email, this.user.uid)
              user.CreateUser(user, family.id)
              this.$router.push('/')
            })
        })
        .catch((error) => {
          alert(error.message)
        })
    }
  }
}
</script>
